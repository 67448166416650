import * as React from "react";
import { Dialog } from "spry-react/Dialog/dialog";
import { ErrorList } from "spry-react/Errors/errorList";
import { TabList } from "spry-react/Tabs/tabList";
import { LabeledTextField } from "spry-react/Text/textField";
import { AccountsApiClient } from "./accountsApiClient";
import { ErrorDisplayList } from "spry-react/Errors/errorDisplayList";
import { FlashMessageCallback } from "spry-react/App/app";
import { LabeledCheckboxField } from "spry-react/Checkbox/labeledCheckboxField";
import { SubmitForm, SubmitButton } from "shared/Components/Shared/actionButtons";

interface Props {
  passwordPolicyRules: string[];
  closeDialog: () => void;
  accountsApiClient: AccountsApiClient;
  flashMessageCallback: FlashMessageCallback;
}

interface State {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  showPassword: boolean;
  errors: ErrorList;
}

export class ChangePasswordDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      showPassword: false,
      errors: new ErrorList([]),
    };
  }

  render() {
    const state = this.state;
    const props = this.props;
    const errors = state.errors;

    const tabKey = "changePassword";
    const tabs = [
      {
        key: tabKey,
        text: "Change Password",
      },
    ];

    const passwordPolicyContent = (
      <ul style={{ maxWidth: "50rem" }}>
        {props.passwordPolicyRules.map((r, i) => (
          <li key={i}>{r}</li>
        ))}
      </ul>
    );

    return (
      <Dialog width="50em">
        <TabList selectedTabKey={tabKey} tabs={tabs} onTabSelect={() => {}} />
        <ErrorDisplayList errors={errors.getList()} />
        <SubmitForm onSubmit={this.onChangePasswordClicked}>
          <div className="pure-g">
            <div className="pure-u-1 field field-h">
              <LabeledTextField
                label="Current Password"
                type="Password"
                required
                autoFocus
                value={state.currentPassword}
                onChange={this.onCurrentPasswordChanged}
                errorMessage={errors.getErrorMessage("CurrentPassword")}
              />
            </div>
          </div>
          <div>
            <p>Your password must meet these rules:</p>
            <ul style={{ margin: "1em 0" }}>
              {props.passwordPolicyRules.map((r, i) => (
                <li key={i}>{r}</li>
              ))}
            </ul>
          </div>
          <div className="pure-g">
            <div className="pure-u-1 field field-h">
              <LabeledTextField
                label="New Password"
                type={state.showPassword ? "text" : "password"}
                required
                value={state.newPassword}
                onChange={this.onNewPasswordChanged}
                errorMessage={errors.getErrorMessage("NewPassword")}
              />
            </div>
          </div>
          <div className="pure-g">
            <div className="pure-u-1 field field-h">
              <LabeledTextField
                label="Confirm New Password"
                type={state.showPassword ? "text" : "password"}
                required
                value={state.confirmNewPassword}
                onChange={this.onConfirmNewPasswordChanged}
                errorMessage={errors.getErrorMessage("ConfirmNewPassword")}
              />
            </div>
          </div>
          <div className="pure-g">
            <div className="pure-u-1 field field-h">
              <LabeledCheckboxField label="Show Password" value={state.showPassword} onToggle={this.onShowPasswordChanged} />
            </div>
          </div>
          <div className="l-box-vt">
            <div className="action-buttons">
              <button className="pure-button pure-button-transparent" onClick={this.props.closeDialog}>
                Cancel
              </button>
              <SubmitButton primary>Change Password</SubmitButton>
            </div>
          </div>
        </SubmitForm>
      </Dialog>
    );
  }

  private onCurrentPasswordChanged = (currentPassword: string) => {
    this.setState({ currentPassword });
  };

  private onNewPasswordChanged = (newPassword: string) => {
    this.setState({ newPassword });
  };

  private onConfirmNewPasswordChanged = (confirmNewPassword: string) => {
    this.setState({ confirmNewPassword });
  };

  private onShowPasswordChanged = () => {
    this.setState((state) => {
      return { showPassword: !state.showPassword };
    });
  };

  private onChangePasswordClicked = () => {
    this.props.accountsApiClient
      .changePassword({
        currentPassword: this.state.currentPassword,
        newPassword: this.state.newPassword,
        confirmNewPassword: this.state.confirmNewPassword,
      })
      .then((response) => {
        if (response.success) {
          this.props.closeDialog();
          this.props.flashMessageCallback("Password changed.");
        } else {
          this.setState({
            errors: new ErrorList(response.errors),
          });
        }
      });
  };
}
