import { SignInRequest } from "Components/Api/GeneratedDTOs/Api/Accounts/SignInRequest";
import { SignInResponse } from "Components/Api/GeneratedDTOs/Api/Accounts/SignInResponse";
import { CreateNewUserRequest } from "Components/Api/GeneratedDTOs/Api/Accounts/CreateNewUserRequest";
import { CreateNewUserResponse } from "Components/Api/GeneratedDTOs/Api/Accounts/CreateNewUserResponse";
import { ConfirmRegisterRequest } from "Components/Api/GeneratedDTOs/Api/Accounts/ConfirmRegisterRequest";
import { AccountsApiClientUrls } from "Components/Api/GeneratedDTOs/Api/Accounts/AccountsApiClientUrls";
import { CreateOrganizationRequest } from "Components/Api/GeneratedDTOs/Api/Commands/Accounts/CreateOrganizationRequest";
import { CreateOrganizationResponse } from "Components/Api/GeneratedDTOs/Api/Commands/Accounts/CreateOrganizationResponse";
import { ConfirmRegisterResponse } from "Components/Api/GeneratedDTOs/Api/Accounts/ConfirmRegisterResponse";
import { GetChangePasswordInfoResponse } from "Components/Api/GeneratedDTOs/Api/Accounts/GetChangePasswordInfoResponse";
import { ChangePasswordRequest } from "Components/Api/GeneratedDTOs/Api/Accounts/ChangePasswordRequest";
import { ChangePasswordResponse } from "Components/Api/GeneratedDTOs/Api/Accounts/ChangePasswordResponse";
import { GetPersonResponse } from "Components/Api/GeneratedDTOs/Api/Accounts/GetPersonResponse";
import { IFetcher } from "spry-react/utils/IFetcher";
import { ConfirmCreateCodeRequest } from "Components/Api/GeneratedDTOs/Api/Accounts/ConfirmCreateCodeRequest";
import { ConfirmCreateCodeResponse } from "Components/Api/GeneratedDTOs/Api/Accounts/ConfirmCreateCodeResponse";
import { ResendCreateCodeResponse } from "Components/Api/GeneratedDTOs/Api/Accounts/ResendCreateCodeResponse";
import { AccountSetupRequest } from "Components/Api/GeneratedDTOs/Api/Accounts/AccountSetupRequest";
import { AccountSetupResponse } from "Components/Api/GeneratedDTOs/Api/Accounts/AccountSetupResponse";
import { ForgotPasswordEmailRequest } from "Components/Api/GeneratedDTOs/Api/Accounts/ForgotPasswordEmailRequest";
import { ForgotPasswordEmailResponse } from "Components/Api/GeneratedDTOs/Api/Accounts/ForgotPasswordEmailResponse";
import { ConfirmPasswordResetCodeRequest } from "Components/Api/GeneratedDTOs/Api/Accounts/ConfirmPasswordResetCodeRequest";
import { ConfirmPasswordResetCodeResponse } from "Components/Api/GeneratedDTOs/Api/Accounts/ConfirmPasswordResetCodeResponse";
import { ResendPasswordResetCodeRequest } from "Components/Api/GeneratedDTOs/Api/Accounts/ResendPasswordResetCodeRequest";
import { ResendPasswordResetCodeResponse } from "Components/Api/GeneratedDTOs/Api/Accounts/ResendPasswordResetCodeResponse";
import { ResetPasswordRequest } from "Components/Api/GeneratedDTOs/Api/Accounts/ResetPasswordRequest";
import { ResetPasswordResponse } from "Components/Api/GeneratedDTOs/Api/Accounts/ResetPasswordResponse";
import { ForgotUsernameEmailRequest } from "Components/Api/GeneratedDTOs/Api/Accounts/ForgotUsernameEmailRequest";
import { ForgotUsernameEmailResponse } from "Components/Api/GeneratedDTOs/Api/Accounts/ForgotUsernameEmailResponse";
import { GetEditUserAccountInfoResponse } from "Components/Api/GeneratedDTOs/Api/Accounts/GetEditUserAccountInfoResponse";
import { EditUserAccountInfoResponse } from "Components/Api/GeneratedDTOs/Api/Accounts/EditUserAccountInfoResponse";
import { EditUserAccountInfoRequest } from "Components/Api/GeneratedDTOs/Api/Accounts/EditUserAccountInfoRequest";
import { OrgMembershipRequestRequest } from "Components/Api/GeneratedDTOs/Api/Accounts/OrgMembershipRequestRequest";
import { OrgMembershipRequestResponse } from "Components/Api/GeneratedDTOs/Api/Accounts/OrgMembershipRequestResponse";
import { ResendMembershipRequestRequest } from "Components/Api/GeneratedDTOs/Api/Accounts/ResendMembershipRequestRequest";
import { ResendMembershipRequestResponse } from "Components/Api/GeneratedDTOs/Api/Accounts/ResendMembershipRequestResponse";
import { CancelMembershipRequestRequest } from "Components/Api/GeneratedDTOs/Api/Accounts/CancelMembershipRequestRequest";
import { CancelMembershipRequestResponse } from "Components/Api/GeneratedDTOs/Api/Accounts/CancelMembershipRequestResponse";
import { GetAccessRequestsRequest } from "Components/Api/GeneratedDTOs/Api/Accounts/GetAccessRequestsRequest";
import { GetAccessRequestsSentResponse } from "Components/Api/GeneratedDTOs/Api/Accounts/GetAccessRequestsSentResponse";
import { CommandFetcher } from "shared/Components/Utils/commandFetcher";

export class AccountsApiClient {
  private readonly signInUrl: string;
  private readonly createNewUserUrl: string;
  private readonly confirmCreateCodeUrl: string;
  private readonly resendCreateCodeUrl: string;
  private readonly accountSetupUrl: string;
  private readonly orgMembershipRequestUrl: string;
  private readonly resendMembershipRequestUrl: string;
  private readonly cancelMembershipRequestUrl: string;
  private readonly getChangePasswordInfoUrl: string;
  private readonly changePasswordUrl: string;
  private readonly getPersonUrl: string;
  private readonly forgotPasswordEmailUrl: string;
  private readonly confirmPasswordResetCodeUrl: string;
  private readonly resendPasswordResetCodeUrl: string;
  private readonly resetPasswordUrl: string;
  private readonly forgotUsernameEmailUrl: string;
  private readonly getEditUserAccountInfoUrl: string;
  private readonly editUserAccountInfoUrl: string;
  private readonly confirmRegisterUrl: string;
  private readonly getAccessRequestsUrl: string;

  private readonly fetcher: IFetcher;
  private readonly commandFetcher: CommandFetcher;

  constructor(accountsApiClientUrls: AccountsApiClientUrls, fetcher: IFetcher, commandFetcher: CommandFetcher) {
    this.signInUrl = accountsApiClientUrls.signInUrl;
    this.createNewUserUrl = accountsApiClientUrls.createNewUserUrl;
    this.confirmCreateCodeUrl = accountsApiClientUrls.confirmCreateCodeUrl;
    this.resendCreateCodeUrl = accountsApiClientUrls.resendCreateCodeUrl;
    this.accountSetupUrl = accountsApiClientUrls.accountSetupUrl;
    this.orgMembershipRequestUrl = accountsApiClientUrls.orgMembershipRequestUrl;
    this.resendMembershipRequestUrl = accountsApiClientUrls.resendMembershipRequestUrl;
    this.cancelMembershipRequestUrl = accountsApiClientUrls.cancelMembershipRequestUrl;
    this.getChangePasswordInfoUrl = accountsApiClientUrls.getChangePasswordInfoUrl;
    this.changePasswordUrl = accountsApiClientUrls.changePasswordUrl;
    this.getPersonUrl = accountsApiClientUrls.getPersonUrl;
    this.forgotPasswordEmailUrl = accountsApiClientUrls.forgotPasswordEmailUrl;
    this.confirmPasswordResetCodeUrl = accountsApiClientUrls.confirmPasswordResetCodeUrl;
    this.resendPasswordResetCodeUrl = accountsApiClientUrls.resendPasswordResetCodeUrl;
    this.resetPasswordUrl = accountsApiClientUrls.resetPasswordUrl;
    this.forgotUsernameEmailUrl = accountsApiClientUrls.forgotUsernameEmailUrl;
    this.getEditUserAccountInfoUrl = accountsApiClientUrls.getEditUserAccountInfoUrl;
    this.editUserAccountInfoUrl = accountsApiClientUrls.editUserAccountInfoUrl;
    this.confirmRegisterUrl = accountsApiClientUrls.confirmRegisterUrl;
    this.getAccessRequestsUrl = accountsApiClientUrls.getAccessRequestsUrl;

    this.fetcher = fetcher;
    this.commandFetcher = commandFetcher;
  }

  public signIn(data: SignInRequest): Promise<SignInResponse> {
    return this.fetcher.postJson(this.signInUrl, data);
  }

  public createNewUser(data: CreateNewUserRequest): Promise<CreateNewUserResponse> {
    return this.fetcher.postJson(this.createNewUserUrl, data);
  }

  public confirmCreateCode(data: ConfirmCreateCodeRequest): Promise<ConfirmCreateCodeResponse> {
    return this.fetcher.postJson(this.confirmCreateCodeUrl, data);
  }

  public resendCreateCode(email: string): Promise<ResendCreateCodeResponse> {
    return this.fetcher.postJson(this.resendCreateCodeUrl, { email });
  }

  public accountSetup(data: AccountSetupRequest): Promise<AccountSetupResponse> {
    return this.fetcher.postJson(this.accountSetupUrl, data);
  }

  public orgMembershipRequest(data: OrgMembershipRequestRequest): Promise<OrgMembershipRequestResponse> {
    return this.fetcher.postJson(this.orgMembershipRequestUrl, data);
  }

  public resendMembershipRequest(data: ResendMembershipRequestRequest): Promise<ResendMembershipRequestResponse> {
    return this.fetcher.postJson(this.resendMembershipRequestUrl, data);
  }

  public cancelMembershipRequest(data: CancelMembershipRequestRequest): Promise<CancelMembershipRequestResponse> {
    return this.fetcher.postJson(this.cancelMembershipRequestUrl, data);
  }

  public confirmRegister(data: ConfirmRegisterRequest): Promise<ConfirmRegisterResponse> {
    return this.fetcher.postJson(this.confirmRegisterUrl, data);
  }

  public createOrganization(data: CreateOrganizationRequest): Promise<CreateOrganizationResponse> {
    return this.commandFetcher.commandExecute("CreateOrganization", data);
  }

  public getChangePasswordInfo(): Promise<GetChangePasswordInfoResponse> {
    return this.fetcher.getJson(this.getChangePasswordInfoUrl, undefined);
  }

  public changePassword(data: ChangePasswordRequest): Promise<ChangePasswordResponse> {
    return this.fetcher.postJson(this.changePasswordUrl, data);
  }

  public getPerson(organizationId: number, personId: number): Promise<GetPersonResponse> {
    return this.fetcher.getJsonWithData(this.getPersonUrl, {
      organizationId,
      personId,
    });
  }

  public forgotPasswordEmail(data: ForgotPasswordEmailRequest): Promise<ForgotPasswordEmailResponse> {
    return this.fetcher.postJson(this.forgotPasswordEmailUrl, data);
  }

  public confirmPasswordResetCode(data: ConfirmPasswordResetCodeRequest): Promise<ConfirmPasswordResetCodeResponse> {
    return this.fetcher.postJson(this.confirmPasswordResetCodeUrl, data);
  }

  public resendPasswordResetCode(data: ResendPasswordResetCodeRequest): Promise<ResendPasswordResetCodeResponse> {
    return this.fetcher.postJson(this.resendPasswordResetCodeUrl, data);
  }

  public resetPassword(data: ResetPasswordRequest): Promise<ResetPasswordResponse> {
    return this.fetcher.postJson(this.resetPasswordUrl, data);
  }

  public forgotUsernameEmail(data: ForgotUsernameEmailRequest): Promise<ForgotUsernameEmailResponse> {
    return this.fetcher.postJson(this.forgotUsernameEmailUrl, data);
  }

  public getEditUserAccountInfo(): Promise<GetEditUserAccountInfoResponse> {
    return this.fetcher.getJson(this.getEditUserAccountInfoUrl);
  }

  public editUserAccountInfo(data: EditUserAccountInfoRequest): Promise<EditUserAccountInfoResponse> {
    return this.fetcher.postJson(this.editUserAccountInfoUrl, data);
  }

  public getAccessRequests(data: GetAccessRequestsRequest): Promise<GetAccessRequestsSentResponse> {
    return this.fetcher.getJsonWithData(this.getAccessRequestsUrl, data);
  }
}
