import * as React from "react";
import { ErrorLogger } from "Components/Shared/errorLogger";

interface Props {
  errorLogger: ErrorLogger;
}

interface State {
  error: any;
  errorInfo: any;
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <>
          <h2>An unexpected error has occurred</h2>
          The details of the error have been logged. Try refreshing the page and trying the operation again. If the problem persists please contact
          technical support.
        </>
      );
    }

    return this.props.children;
  }

  componentDidCatch?(error: Error, errorInfo: React.ErrorInfo): void {
    //We're manually logging this error here to get the component stack.
    //The errorLoggerListener will also log react's console.error call, but it doesn't have as much information
    this.props.errorLogger.log({
      message: error.message,
      pageUrl: window.location.href,
      loggedFrom: "ErrorBoundary.ComponentDidCatch",
      stack: error.stack || null,
      componentStack: errorInfo.componentStack,
      scriptUrl: null,
      userAgent: navigator.userAgent,
      line: null,
    });

    this.setState({ error, errorInfo });
  }
}
