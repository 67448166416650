import { RpcCommandApiUrls } from "../Api/CustomDTOs/RpcCommandApiUrls";

/** This is effectively a singleton instance of the RpcCommandApiUrls.  It has to be set once by the main
 * App component and then it can be accessed by the code generated command api methods.
 */
interface CommandApiUrlsSingleton {
  commandApiUrls: RpcCommandApiUrls;
}

/** Sets the singleton RpcCommandApiUrls to be accessed by the code generated command api methods. */
export function registerCommandApiUrls(urls: RpcCommandApiUrls) {
  (window as unknown as CommandApiUrlsSingleton).commandApiUrls = urls;
}

/** Gets the singleton RpcCommandApiUrls. */
export function getCommandApiUrls(): RpcCommandApiUrls {
  const commandApiUrls = (window as unknown as CommandApiUrlsSingleton).commandApiUrls;
  if (!commandApiUrls) throw "CommandApiUrls have not been registered. Use registerCommandApiUrls() when initializing your app";

  return commandApiUrls;
}
