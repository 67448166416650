import * as React from "react";
import { HiddenHeader, StackedTextHeader, IconCell, StackedTextCell, StackedLinkCell } from "spry-react/Grid/gridCells";
import { RowDefinition, Grid } from "spry-react/Grid/grid";
import { GridFooter } from "spry-react/Grid/gridFooter";
import { H1SectionHeader } from "spry-react/Section/section";
import { HelpFileDTO } from "shared/Components/Api/GeneratedDTOs/DTOs/PortalHelpPage/HelpFileDTO";

interface Props {
  files: HelpFileDTO[];
}

export function HelpFiles(props: Props) {
  const headers = [
    <HiddenHeader basis="2rem" growFactor={0} />,
    <StackedTextHeader label1="Name" label2="Type" basis="3rem" />,
    <StackedTextHeader label1="Description" basis="5rem" />,
  ];

  const rows: RowDefinition[] = props.files
    .sort((first, second) => first.order - second.order)
    .map((file) => {
      return {
        keyValue: file.id,
        cells: [
          <IconCell iconClass={"filetype-icon " + file.extensionClassName} title={file.extensionTitle} centerContent={false} />,
          <StackedLinkCell
            textValueTop={file.name}
            textValueBottom={file.extensionTypeName}
            linkPropsTop={{ url: file.downloadLink ? file.downloadLink : "" }}
          />,
          <StackedTextCell value1={file.description || ""} />,
        ],
      };
    });

  return (
    <div>
      <h1 className="l-box-vb-1-2x">Helpful Files</h1>
      <Grid headers={headers} rows={rows} noDataMessage={<span>No files found.</span>} />
      <GridFooter resultsMessage={`${props.files.length} files`} />
    </div>
  );
}
