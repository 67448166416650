import { CommandFetcher } from "shared/Components/Utils/commandFetcher";
import { HelpDialogResponse } from "Components/Api/GeneratedDTOs/Api/Commands/PortalHelpPage/HelpDialogResponse";
import { HelpDialogPortalHelpPageLoadRequest } from "Components/Api/GeneratedDTOs/Api/Commands/PortalHelpPage/HelpDialogPortalHelpPageLoadRequest";
import { HelpDialogPortalHelpPageLoadResponse } from "Components/Api/GeneratedDTOs/Api/Commands/PortalHelpPage/HelpDialogPortalHelpPageLoadResponse";

export class HelpDialogApiClient {
  private readonly commandFetcher: CommandFetcher;

  constructor(commandFetcher: CommandFetcher) {
    this.commandFetcher = commandFetcher;
  }

  public loadHelpDialog(): Promise<HelpDialogResponse> {
    return this.commandFetcher.commandLoad<HelpDialogResponse>("HelpDialogLoad");
  }

  public loadPortalHelpPage(request: HelpDialogPortalHelpPageLoadRequest): Promise<HelpDialogPortalHelpPageLoadResponse> {
    return this.commandFetcher.commandLoad("HelpDialogPortalHelpPageLoad", request);
  }
}
