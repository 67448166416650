﻿import * as React from "react";
import * as classnames from "classnames";

export interface Props {
  renderLeft?: React.ReactNode;
}

export class ActionButtons extends React.Component<Props, {}> {
  render() {
    return (
      <div className="l-box-vt">
        {this.props.renderLeft ? (
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <div style={{ flexGrow: 0 }}>{this.props.renderLeft}</div>
            <div style={{ flexGrow: 1 }}>{this.renderActionButtons()}</div>
          </div>
        ) : (
          this.renderActionButtons()
        )}
      </div>
    );
  }

  private renderActionButtons = () => {
    return <div className="action-buttons">{this.props.children}</div>;
  };
}

export interface ActionButtonBaseProps {
  transparent?: boolean;
  primary?: boolean;
  disabled?: boolean;
  title?: string;
  hasSubtext?: boolean;
}

export interface ActionButtonClickProps extends ActionButtonBaseProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

function getClasses(props: ActionButtonBaseProps) {
  const transparent = props.transparent ? "pure-button-transparent" : "";
  const primary = props.primary ? "pure-button-primary" : "";
  const subtext = props.hasSubtext ? "button-with-subtext" : "";
  return classnames("pure-button", transparent, primary, subtext);
}

function getBaseButtonProps(props: ActionButtonBaseProps) {
  return {
    className: getClasses(props),
    disabled: props.disabled,
    title: props.title,
  };
}

export class ActionButton extends React.Component<ActionButtonClickProps, {}> {
  render() {
    return (
      <button onClick={this.props.onClick} {...getBaseButtonProps(this.props)}>
        {this.props.children}
      </button>
    );
  }
}

export interface SubmitFormProps {
  onSubmit: () => void;
}

// When you want your form to submit when the user hits the enter key while
// they have focus on any input in the form, then this is the component to use
export class SubmitForm extends React.Component<SubmitFormProps, {}> {
  render() {
    // HTML forms by default handle the enter key by finding the first <button type="submit"> in the form and clicking it.
    // Button's that do not explicitly set the type attribute default to submit!  So if any field has a button, it could end up
    // receiving the click instead of the form's real submit button, which is not what we want.
    // To guarantee that an enter key press which bubbled up to the form always causes the form to submit we handle the onKeyPress
    // ourselves.
    // We still also need to hook onSubmit for when the submit button is directly clicked.
    return (
      <form onSubmit={this.handleOnSubmit} onKeyPress={this.handleFormKeyPress}>
        {this.props.children}
      </form>
    );
  }

  private handleFormKeyPress = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();

      this.props.onSubmit();
    }
  };

  private handleOnSubmit = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    this.props.onSubmit();
  };
}

// SubmitButton submits the form that it is contained in when it is clicked
export class SubmitButton extends React.Component<ActionButtonBaseProps, {}> {
  render() {
    return (
      <button type="submit" {...getBaseButtonProps(this.props)}>
        {this.props.children}
      </button>
    );
  }
}
