import * as React from "react";
import { Dialog } from "spry-react/Dialog/dialog";

export function Render401ErrorDialog(close: () => void) {
  return <Error401Dialog close={close} />;
}

export interface Props {
  close: () => void;
}

export class Error401Dialog extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Dialog singleTabText="Disconnected" width="50em">
        <p style={{ fontSize: "1.3em" }}>Your session has expired. Please refresh the page and login.</p>
        <div className="pure-g">
          <div className="pure-u-1">
            <div className="action-buttons l-box-vt-2x">
              <button className="pure-button pure-button-transparent" onClick={this.props.close}>
                Close
              </button>
              <button className="pure-button pure-button-primary" onClick={this.refresh}>
                Refresh
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }

  private refresh = () => {
    window.location.reload();
  };
}
