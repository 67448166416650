import * as fetch from "spry-react/utils/fetchExtensions";
import { LoggableError } from "shared/Components/Api/GeneratedDTOs/DTOs/LoggableError";

export class ErrorLogger {
  private readonly errorLoggingUrl: string;
  private readonly getCsrfInfo: () => fetch.CrossSiteRequestForgeryTokenInfo;

  constructor(errorLoggingUrl: string, getCsrfInfo: () => fetch.CrossSiteRequestForgeryTokenInfo) {
    this.errorLoggingUrl = errorLoggingUrl;
    this.getCsrfInfo = getCsrfInfo;
  }

  public log = (error: LoggableError) => {
    try {
      fetch.postJson(this.errorLoggingUrl, error, this.getCsrfInfo).catch(() => {
        /* ignore any api errors */
      });
    } catch {
      /* ignore any thrown js errors */
    }
  };
}
