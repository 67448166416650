﻿import { UndoState } from "./undoState";
import { PropertyError } from "spry-react/utils/apiTypes";

export interface EditorState<T> extends UndoState<T> {
  errors: PropertyError[];
  warnings: PropertyError[];
}

export function New<T>(originalState: T, warnings: PropertyError[] = [], errors: PropertyError[] = []): EditorState<T> {
  return {
    originalState,
    currentState: originalState,
    errors,
    warnings,
  };
}

interface UpdateParams<T> {
  next?: T;
  errors?: PropertyError[];
  warnings?: PropertyError[];
}

export function Update<T>(prev: EditorState<T>, params: UpdateParams<T>): EditorState<T> {
  return {
    originalState: prev.originalState,
    currentState: params.next ? params.next : prev.currentState,
    errors: params.errors ? params.errors : prev.errors,
    warnings: params.warnings ? params.warnings : prev.warnings,
  };
}
