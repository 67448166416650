import { ErrorLogger } from "./errorLogger";

export namespace ErrorLoggerListener {
  let initialized: boolean = false;
  let errLogger: ErrorLogger | undefined;
  let baseConsoleError: ((message?: any, ...optionalParams: any[]) => void) | undefined;

  export function start(errorLogger: ErrorLogger) {
    if (initialized) return;

    errLogger = errorLogger;

    //Overwrite console.error so any error logging gets sent to the server even if there is no exception
    //e.g. React logs duplicate key errors but does not throw, we want these errors persisted
    baseConsoleError = console.error;
    console.error = consoleError;

    //Log any uncaught exceptions
    //e.g. exceptions caught outside the react Error Boundary/on old V3 pages
    window.addEventListener("error", logErrorEvent);

    initialized = true;
  }

  export function stop() {
    if (!initialized) return;

    console.error = baseConsoleError as (message?: any, ...optionalParams: any[]) => void;
    window.removeEventListener("error", logErrorEvent);

    errLogger = undefined;
    baseConsoleError = undefined;
    initialized = false;
  }

  function consoleError(message?: string) {
    baseConsoleError!.apply(console, arguments);

    var substitutedMessage = message;
    if (substitutedMessage !== undefined && arguments.length > 1) {
      var otherArgs = Array.prototype.slice.call(arguments, 1);
      var count = 0;
      substitutedMessage = substitutedMessage.replace(/%s/g, () => otherArgs[count++]);
    }

    errLogger!.log({
      message: substitutedMessage || null,
      pageUrl: window.location.href,
      loggedFrom: "console.error",
      stack: null,
      componentStack: null,
      scriptUrl: null,
      userAgent: null,
      line: null,
    });
  }

  function logErrorEvent(e: ErrorEvent) {
    errLogger!.log({
      message: e.message,
      pageUrl: window.location.href,
      loggedFrom: "window.onerror",
      stack: e.error ? e.error.stack : null,
      componentStack: null,
      scriptUrl: e.filename,
      userAgent: navigator.userAgent,
      line: e.lineno,
    });
  }
}
