import * as React from "react";
import { Dialog } from "spry-react/Dialog/dialog";
import { ActionButtons, ActionButton } from "shared/Components/Shared/actionButtons";
import { TabList, TabDefinition } from "spry-react/Tabs/tabList";
import { HelpText } from "./helpText";
import { HelpFiles } from "./helpFile";
import { IPortalAppContext } from "Components/Shared/appContext";
import { HelpDialogResponse } from "Components/Api/GeneratedDTOs/Api/Commands/PortalHelpPage/HelpDialogResponse";
import { VerticalSelectList, VerticalSelectListItem } from "spry-react/VerticalSelectList/verticalSelectList";
import { HelpDialogApiClient } from "./helpDialogApiClient";
import { SimpleCode } from "shared/Components/Api/GeneratedDTOs/SimpleCode";
import { HelpFileDTO } from "shared/Components/Api/GeneratedDTOs/DTOs/PortalHelpPage/HelpFileDTO";

interface Props {
  helpInfo: HelpDialogResponse;
  closeDialog: () => void;
  context: IPortalAppContext;
  apiClient: HelpDialogApiClient;
}

interface State {
  selectedPortalHelpPage: SimpleCode;
  helpText: string;
  helpfulFiles: HelpFileDTO[];
  selectedTabKey: string;
}

export function PortalHelpDialog(props: Props) {
  const [helpTextKey, helpFilesKey] = ["HelpText", "HelpFiles"];
  const [selectedTabKey, setTab] = React.useState(helpTextKey);
  const [state, setState] = React.useState({
    selectedPortalHelpPage: props.helpInfo.selectedHelpPage,
    helpText: props.helpInfo.portalHelpPageData.helpText,
    helpfulFiles: props.helpInfo.portalHelpPageData.helpFiles,
    selectedTabKey: helpTextKey,
  } as State);

  const tabs: TabDefinition[] = [
    { key: helpTextKey, text: "Help" },
    { key: helpFilesKey, text: "Helpful Files" },
  ];

  return (
    <Dialog width="110rem">
      <TabList selectedTabKey={selectedTabKey} onTabSelect={setTab} tabs={tabs} />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ flex: "0 0 20rem", paddingRight: "1.3rem" }}>
          <VerticalSelectList>
            {props.helpInfo.helpPages.map((helpPage) => (
              <VerticalSelectListItem key={helpPage.id} selected={state.selectedPortalHelpPage.id === helpPage.id}>
                <div onClick={() => handlePortalHelpPageClicked(helpPage)} style={{ display: "flex", justifyContent: "space-between" }}>
                  <h4>{helpPage.name}</h4>
                </div>
              </VerticalSelectListItem>
            ))}
          </VerticalSelectList>
        </div>
        <div style={{ display: "flex", flexDirection: "column", flex: "1 0 0", paddingLeft: "1.3rem", justifyContent: "space-between" }}>
          <div>{selectedTabKey === helpTextKey ? <HelpText helpText={{ __html: state.helpText }} /> : <HelpFiles files={state.helpfulFiles} />}</div>
          <div>
            <ActionButtons>
              <ActionButton primary onClick={props.closeDialog}>
                Close
              </ActionButton>
            </ActionButtons>
          </div>
        </div>
      </div>
    </Dialog>
  );

  async function handlePortalHelpPageClicked(helpPage: SimpleCode) {
    const response = await props.apiClient.loadPortalHelpPage({ portalHelpPageId: helpPage.id });

    setState({
      ...state,
      helpText: response.portalHelpPageData.helpText,
      helpfulFiles: response.portalHelpPageData.helpFiles,
      selectedPortalHelpPage: helpPage,
    });
  }
}
