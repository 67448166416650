import * as React from "react";

interface Props {
  helpText: HelpTextHtml;
}

interface HelpTextHtml {
  __html: string;
}

export function HelpText(props: Props) {
  return <div dangerouslySetInnerHTML={props.helpText} />;
}
