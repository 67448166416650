import { OpenDialogCallback } from "spry-react/App/app";
import { RenderNetworkErrorDialog } from "spry-react/Errors/networkErrorDialog";
import { Render500ErrorDialog } from "spry-react/Errors/500ErrorDialog";
import { Render404ErrorDialog } from "spry-react/Errors/404ErrorDialog";
import { Render403ErrorDialog } from "spry-react/Errors/403ErrorDialog";
import { CrossSiteRequestForgeryTokenInfo, ErrorHandlers } from "spry-react/utils/fetchExtensions";
import { FetchWithStandardErrorHandling } from "spry-react/utils/fetchWithStandardErrorHandling";
import { CommandFetcher } from "../Utils/commandFetcher";
import { FetchWithSerialOperationHandling } from "spry-react/utils/fetchWithSerialOperationHandling";
import { registerCommandApiUrls } from "shared/Components/Utils/getCommandApiUrls";
import * as ApiClient from "spry-react/utils/apiClient";
import { Render401ErrorDialog } from "./401ErrorDialog";
import { StartSerialOperationCallback } from "spry-react/SerialOperation/serialOperation";
import { RpcCommandApiUrls } from "../Api/CustomDTOs/RpcCommandApiUrls";
import { buildCommandRequest } from "../Utils/buildCommandRequest";
import { registerBuildCommandRequest as dataExplorerRegisterBuildCommandRequest } from "pbs-dataexplorer/dataExplorerCommandConfig";
import {
  NetworkErrorMessage,
  NotFoundRoutingErrorMessage,
  Routing401ErrorMessage,
  Routing403ErrorMessage,
  Routing500ErrorMessage,
} from "./routerErrorStrings";

/** Shares the configuration of the fetchers so all the top level pages can use the same setup. */
export function configureFetchers(
  openDialog: OpenDialogCallback,
  startSerialOperation: StartSerialOperationCallback,
  getCsrfInfo: () => CrossSiteRequestForgeryTokenInfo,
  rpcCommandApiUrls?: RpcCommandApiUrls,
) {
  const openNetworkErrorDialog = () => {
    openDialog(RenderNetworkErrorDialog);
  };
  const open500ErrorDialog = () => {
    openDialog(Render500ErrorDialog);
  };
  const open404ErrorDialog = () => {
    openDialog(Render404ErrorDialog);
  };
  const open401ErrorDialog = () => {
    openDialog(Render401ErrorDialog);
  };
  const open403ErrorDialog = () => {
    openDialog(Render403ErrorDialog);
  };
  const dialogErrorHandlers: ErrorHandlers = {
    handle401Error: open401ErrorDialog,
    handle403Error: open403ErrorDialog,
    handle404Error: open404ErrorDialog,
    handle500Error: open500ErrorDialog,
    handleNetworkError: openNetworkErrorDialog,
  };
  const errorFetcher = new FetchWithStandardErrorHandling({
    ...dialogErrorHandlers,
    getCsrfInfo,
  });
  const serialFetcher = new FetchWithSerialOperationHandling(startSerialOperation, errorFetcher);

  let commandFetcher: CommandFetcher | undefined;
  if (rpcCommandApiUrls) {
    commandFetcher = new CommandFetcher(serialFetcher, rpcCommandApiUrls);
    registerCommandApiUrls(rpcCommandApiUrls);
    dataExplorerRegisterBuildCommandRequest(buildCommandRequest);
  }

  const standardApiClient = new ApiClient.ApiClient([
    new ApiClient.CsrfInfoStep(getCsrfInfo),
    new ApiClient.StandardErrorHandlingStep(dialogErrorHandlers),
  ]);
  const serialOperationApiClient = new ApiClient.SerialOperationApiClient(startSerialOperation, standardApiClient);

  const throwNetworkError = () => {
    throw new Error(NetworkErrorMessage);
  };
  const throw500Error = () => {
    throw new Error(Routing500ErrorMessage);
  };
  const throw404Error = () => {
    throw new Error(NotFoundRoutingErrorMessage);
  };
  const throw401Error = () => {
    throw new Error(Routing401ErrorMessage);
  };
  const throw403Error = () => {
    throw new Error(Routing403ErrorMessage);
  };
  const throwErrorHandlers: ErrorHandlers = {
    handle401Error: throw401Error,
    handle403Error: throw403Error,
    handle404Error: throw404Error,
    handle500Error: throw500Error,
    handleNetworkError: throwNetworkError,
  };
  const loaderApiClient = new ApiClient.ApiClient([
    new ApiClient.CsrfInfoStep(getCsrfInfo),
    new ApiClient.StandardErrorHandlingStep(throwErrorHandlers),
  ]);

  return {
    serialFetcher,
    commandFetcher,
    standardApiClient,
    serialOperationApiClient,
    loaderApiClient,
  };
}
