import * as React from "react";
import { FullPage } from "spry-react/Page/fullPage";
import { Page } from "spry-react/Page/page";
import { IPortalAppContext } from "./appContext";
import { PortalFullHeader } from "./portalFullHeader";

export interface Props {
  title: string;
  headerContent: React.ReactNode;
  children?: React.ReactNode;
  borderedHeader?: boolean;
  context: IPortalAppContext;
}

export class PortalFullPage extends React.Component<Props> {
  render() {
    const props = this.props;
    const header = renderHeader(props);
    return <FullPage {...this.props.context} {...this.props} header={header} />;
  }
}

export class PortalPage extends React.Component<Props> {
  render() {
    const props = this.props;
    const header = renderHeader(props);
    return <Page {...this.props.context} {...this.props} header={header} />;
  }
}

function renderHeader(props: Readonly<{ children?: React.ReactNode }> & Readonly<Props>) {
  return (
    <PortalFullHeader
      homeUrl={props.context.homeUrl}
      homeLinkText={props.context.homeLinkText}
      logoImageUrl={props.context.logoImageUrl}
      myOrganizationsUrl={props.context.myOrganizationsUrl}
      signOutUrl={props.context.signOutUrl}
      bordered={props.borderedHeader}
      context={props.context}
      logoColor={props.context.logoColor}
      logoLabel={props.context.logoLabel}
    >
      {props.headerContent}
    </PortalFullHeader>
  );
}
