﻿import * as React from "react";
import * as classnames from "classnames";
import produce from "immer";

export class PureG extends React.Component<{ className?: string; style?: React.CSSProperties }, {}> {
  render() {
    return (
      <div className={classnames(this.props.className, "pure-g")} style={this.props.style}>
        {this.props.children}
      </div>
    );
  }
}

export interface PureUProps {
  size?: PureUSizes;
  className?: string;
  style?: React.CSSProperties;
  title?: string;
}

export class PureU extends React.Component<PureUProps, {}> {
  render() {
    return (
      <div className={classnames(this.props.className, PureUSizeClass(this.props.size))} style={this.props.style} title={this.props.title}>
        {this.props.children}
      </div>
    );
  }
}

export type PureUSizes =
  | "1"
  | "1-2"
  | "1-3"
  | "2-3"
  | "1-4"
  | "3-4"
  | "1-6"
  | "1-8"
  | "3-8"
  | "5-8"
  | "7-8"
  | "5-12"
  | "7-12"
  | "1-16"
  | "1-24"
  | "5-24"
  | "6-24"
  | "9-24"
  | "13-24"
  | "15-24"
  | "17-24"
  | "24-24";

export function PureUSizeClass(size?: PureUSizes) {
  return size ? `pure-u-${size}` : "pure-u-1";
}

export interface FieldProps {
  inline?: boolean;
  size?: PureUSizes;
  style?: React.CSSProperties;
  className?: string;
  noPadding?: boolean;
}

export class Field extends React.Component<FieldProps, {}> {
  render() {
    const { inline, size, children, style, className, noPadding } = this.props;
    const inlineClass = inline ? "field-inline" : "";
    const noPaddingClass = noPadding ? "field-v-0" : "";
    const classes = classnames("field", "field-h", PureUSizeClass(size), inlineClass, className, noPaddingClass);

    let styles = style;
    if (inline)
      styles = style
        ? produce(style, (style) => {
            style.paddingBottom = "1rem";
          })
        : { paddingBottom: "1rem" };

    return (
      <div className={classes} style={styles}>
        {children}
      </div>
    );
  }
}

export class PureGU1Field extends React.Component<{}, {}> {
  render() {
    return (
      <div className="pure-g">
        <div className="pure-u-1 field field-h">{this.props.children}</div>
      </div>
    );
  }
}
