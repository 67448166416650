import { FeedbackType } from "spry-react/SerialOperation/serialOperation";
import { ApiRequest, SerialOperationApiRequestOptions } from "spry-react/utils/apiClient";
import { getCommandApiUrls } from "./getCommandApiUrls";

export function buildCommandRequest<TResponse>(commandName: string, exeOrLoad: "EXE" | "LOAD", request: any): ApiRequest<TResponse> {
  const urls = getCommandApiUrls();
  const options: SerialOperationApiRequestOptions = {
    serialOperationOptions: {
      feedbackType: exeOrLoad === "EXE" ? FeedbackType.FullPageSpinner : FeedbackType.LoadingBanner,
    },
  };

  return {
    method: "POST",
    url: exeOrLoad === "EXE" ? `${urls.apiExecuteUrl}${commandName}` : `${urls.apiLoadUrl}${commandName}`,
    body: { name: commandName, request: request },
    options: options,
  };
}

export function buildWithFilesCommandRequest<TResponse>(commandName: string, request: any, files: File[]): ApiRequest<TResponse> {
  const urls = getCommandApiUrls();
  const options: SerialOperationApiRequestOptions = {
    serialOperationOptions: {
      feedbackType: FeedbackType.FullPageSpinner,
    },
  };

  const body = new FormData();
  body.append("Request", JSON.stringify(request));
  files.forEach((f) => body.append("File", f));

  return {
    method: "POST",
    url: `${urls.apiExecuteWithFilesUrl}${commandName}`,
    body: body,
    options: options,
  };
}

export function buildUploadFilesCommandRequest<TResponse>(commandName: string, token: string, filesForm: FormData): ApiRequest<TResponse> {
  const urls = getCommandApiUrls();
  const options: SerialOperationApiRequestOptions = {
    serialOperationOptions: {
      feedbackType: FeedbackType.FullPageSpinner,
    },
  };

  return {
    method: "POST",
    url: `${urls.apiUploadFilesUrl}${commandName}&token=${token}`,
    body: filesForm,
    options: options,
  };
}
