import "./portalFullHeader.less";

import * as React from "react";
import { SiteHeader } from "spry-react/Page/siteHeader";
import { ChangePasswordDialog } from "Components/Accounts/changePasswordDialog";
import { ContextMenu, ContextMenuContainer, ContextMenuItem, ContextMenuOpenerRef } from "spry-react/ContextMenu/contextMenu";
import { EditUserAccountDialog } from "Components/Accounts/editUserAccountDialog";
import { IPortalAppContext } from "./appContext";
import { Link } from "spry-react/Link/link";
import { PortalHelpDialog } from "Components/HelpDialog/helpDialog";
import { FullPageLoadingBanner } from "shared/Components/shared/fullPageLoadingBanner";
import { HelpDialogApiClient } from "Components/HelpDialog/helpDialogApiClient";
import { UseContextMenuOpenerRefClassWrapper } from "spry-react/ContextMenu/useContextMenuOpenerRefClassWrapper";

interface Props {
  homeUrl: string;
  homeLinkText: string;
  logoImageUrl: string;
  myOrganizationsUrl: string;
  signOutUrl: string;
  bordered?: boolean;
  context: IPortalAppContext;
  children?: React.ReactNode;
  logoColor?: string;
  logoLabel?: string;
}

interface State {
  isUserMenuOpen: boolean;
}

export class PortalFullHeader extends React.Component<Props, State> {
  private userMenuOpener: ContextMenuOpenerRef;

  constructor(props: Props) {
    super(props);

    this.state = {
      isUserMenuOpen: false,
    };
  }

  render() {
    const props = this.props;

    return (
      <div>
        <UseContextMenuOpenerRefClassWrapper>
          {(mo) => {
            this.userMenuOpener = mo;
            return <ContextMenuContainer contextMenuOpenerRef={this.userMenuOpener}>{this.renderUserContextMenu()}</ContextMenuContainer>;
          }}
        </UseContextMenuOpenerRefClassWrapper>
        <FullPageLoadingBanner />
        <section id="site-top" className="clearfix">
          <div className="portal-site-links">
            <a href={props.homeUrl} className="site-header-button home-icon">
              {props.homeLinkText}
            </a>
          </div>
          <div className="portal-site-buttons">{this.renderLoggedInButtons()}</div>
        </section>
        <SiteHeader
          homeUrl={props.homeUrl}
          logoImageUrl={props.logoImageUrl}
          bordered={props.bordered}
          children={props.children}
          logoColor={props.logoColor}
          logoLabel={props.logoLabel}
        />
      </div>
    );
  }

  private renderLoggedInButtons = () => {
    const props = this.props;
    const user = props.context.loggedInUser;
    if (!user) return null;

    const handleHelpClicked = async () => {
      const apiClient = new HelpDialogApiClient(props.context.commandFetcher);
      const response = await apiClient.loadHelpDialog();
      props.context.openDialog((close) => {
        return <PortalHelpDialog helpInfo={response} closeDialog={close} context={props.context} apiClient={apiClient} />;
      });
    };

    return (
      <div className="site-header-right-buttons">
        <Link className="site-header-button help-button" onClick={handleHelpClicked}>
          Help
        </Link>
        <a className="site-header-button" href={props.myOrganizationsUrl}>
          My Organizations
        </a>
        <a
          id="open-user-menu"
          className="site-header-button site-header-flyout-button"
          title={user.informalDisplayName}
          onClick={(e) => this.userMenuOpener?.current?.toggleOpen({ relativeElement: e.currentTarget })}
        >
          {user.informalDisplayName}
        </a>
        {user.timeTravelDate && <span className="l-box-hr">{"SIMULATING " + user.timeTravelDate}</span>}
      </div>
    );
  };

  private renderUserContextMenu = () => {
    return (
      <ContextMenu>
        <ContextMenuItem icon="password" text="Change Password" onClick={this.onChangePasswordClicked} />
        <ContextMenuItem icon="users" text="Edit User Account" onClick={this.onEditUserAccountClicked} />
        <ContextMenuItem icon="logout" text="Logout" url={this.props.signOutUrl} />
      </ContextMenu>
    );
  };

  private onChangePasswordClicked = () => {
    this.props.context.accountsApiClient.getChangePasswordInfo().then((response) => {
      this.props.context.openDialog((closeDialog) => {
        return (
          <ChangePasswordDialog
            closeDialog={closeDialog}
            passwordPolicyRules={response.passwordPolicyRules}
            accountsApiClient={this.props.context.accountsApiClient}
            flashMessageCallback={this.props.context.flashMessage}
          />
        );
      });
    });
  };

  private onEditUserAccountClicked = () => {
    this.props.context.accountsApiClient.getEditUserAccountInfo().then((response) => {
      this.props.context.openDialog((closeDialog) => {
        return (
          <EditUserAccountDialog
            closeDialog={closeDialog}
            accountsApiClient={this.props.context.accountsApiClient}
            context={this.props.context}
            initialData={{
              name: response.name,
              username: response.username,
              email: response.email,
              phone: response.phone,
              sendNewsletter: response.sendNewsletter,
            }}
            namePrefixes={response.namePrefixes}
            nameSuffixes={response.nameSuffixes}
          />
        );
      });
    });
  };
}
