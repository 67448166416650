import * as React from "react";

import { IPortalAppContext } from "./appContext";
import { App } from "spry-react/App/app";
import { Layout } from "Components/Api/GeneratedDTOs/Api/Shared/Layout";
import { AccountsApiClient } from "../Accounts/accountsApiClient";
import { CrossSiteRequestForgeryTokenInfo } from "spry-react/utils/fetchExtensions";
import { ErrorLoggerListener } from "shared/Components/Shared/errorLoggerListener";
import { ErrorBoundary } from "shared/Components/Shared/errorBoundary";
import { ErrorLogger } from "shared/Components/Shared/errorLogger";
import { configureFetchers } from "shared/Components/Shared/configureFetchers";

interface WithLayout {
  layout: Layout;
}

export interface Props {
  data: WithLayout;
  renderChildren: (
    appContext: IPortalAppContext,
  ) => React.ReactElement<any> | React.ReactElement<any>[] | React.ReactPortal | string | number | null | false;
}
export interface State {
  csrfInfo: CrossSiteRequestForgeryTokenInfo;
}

export class PortalApp extends React.Component<Props, State> {
  private readonly errorLogger: ErrorLogger;

  constructor(props: Props) {
    super(props);

    this.state = {
      csrfInfo: props.data.layout.csrfInfo,
    };
    this.errorLogger = new ErrorLogger(this.props.data.layout.errorLoggingUrl, () => this.state.csrfInfo);
  }

  componentDidMount() {
    ErrorLoggerListener.start(this.errorLogger);
  }

  componentWillUnmount() {
    ErrorLoggerListener.stop();
  }

  render() {
    return (
      <ErrorBoundary errorLogger={this.errorLogger}>
        <App
          renderChildren={(openDialog, registerBeforeUnload, flashMessage, confirm, startSerialOperation, setWindowLocationHref) => {
            const layout = this.props.data.layout;
            const getCsrfInfo = () => this.state.csrfInfo;
            const fetchers = configureFetchers(openDialog, startSerialOperation, getCsrfInfo, layout.jsonCommandApiUrls);

            const accountsApiClient = new AccountsApiClient(
              this.props.data.layout.accountsApiClientUrls,
              fetchers.serialFetcher,
              fetchers.commandFetcher!,
            );

            const context: IPortalAppContext = {
              fetcher: fetchers.serialFetcher,
              commandFetcher: fetchers.commandFetcher!,
              serialOperationApiClient: fetchers.serialOperationApiClient,
              standardApiClient: fetchers.standardApiClient,
              openDialog,
              confirm,
              flashMessage,
              registerBeforeUnload,
              setWindowLocationHref,

              homeUrl: layout.homeUrl,
              homeLinkText: layout.homeLinkText,
              myOrganizationsUrl: layout.myOrganizationsUrl,
              signOutUrl: layout.signOutUrl,
              logoImageUrl: layout.logoImageUrl,
              loggedInUser: layout.loggedInUser,
              accountsApiClient: accountsApiClient,
              getCsrfInfo: () => this.state.csrfInfo,
              startSerialOperation: startSerialOperation,
              logoColor: layout.logoColor,
              logoLabel: layout.logoLabel,
            };
            return this.props.renderChildren(context);
          }}
        />
      </ErrorBoundary>
    );
  }
}
