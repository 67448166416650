import * as React from "react";

import { AccountsApiClient } from "./accountsApiClient";
import { IPortalAppContext } from "Components/Shared/appContext";
import { PersonName } from "Components/Api/CustomDTOs/PersonName";
import { PhoneNumber, LabeledPhoneField } from "spry-react/Phone/phoneField";
import { EditorState, New as NewEditorState, Update as UpdateEditorState } from "shared/Components/Shared/editorState";
import { Dialog } from "spry-react/Dialog/dialog";
import { ActionButtons, ActionButton } from "shared/Components/Shared/actionButtons";
import { ErrorDisplayList } from "spry-react/Errors/errorDisplayList";
import { ErrorList } from "spry-react/Errors/errorList";
import { LabeledNameField, Name } from "spry-react/Name/nameField";
import { toSelectFieldOptions } from "spry-react/utils/listUtils";
import { PureG, Field } from "shared/Components/Shared/pureG";
import { LabeledTextField } from "spry-react/Text/textField";
import { LabeledCheckboxField } from "spry-react/Checkbox/labeledCheckboxField";
import { Link } from "spry-react/Link/link";
import { ChangePasswordDialog } from "./changePasswordDialog";
import { EditUserAccountInfoRequest } from "Components/Api/GeneratedDTOs/Api/Accounts/EditUserAccountInfoRequest";
import { NamedCode } from "shared/Components/Api/GeneratedDTOs/NamedCode";

interface Props {
  context: IPortalAppContext;
  accountsApiClient: AccountsApiClient;
  closeDialog: () => void;
  initialData: Data;
  namePrefixes: NamedCode[];
  nameSuffixes: NamedCode[];
}

interface State {
  data: EditorState<Data>;
}

interface Data {
  name: PersonName;
  username: string;
  email: string;
  phone: PhoneNumber;
  sendNewsletter: boolean;
}

export class EditUserAccountDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      data: NewEditorState(props.initialData),
    };
  }

  render() {
    const props = this.props;
    const state = this.state;
    const data = this.state.data.currentState;
    const errors = new ErrorList(state.data.errors);

    const namePrefixes = toSelectFieldOptions(props.namePrefixes);
    const nameSuffixes = toSelectFieldOptions(props.nameSuffixes);
    const name = { ...data.name, isUnknown: false };

    return (
      <Dialog width="50em" singleTabText="Edit User Account">
        <div>
          <ErrorDisplayList errors={errors.getList()} />
          <PureG>
            <Field>
              <LabeledNameField
                required
                autoFocus
                label="Name"
                name={name}
                onNameChange={this.onNameChanged}
                prefixes={namePrefixes}
                suffixes={nameSuffixes}
                errorMessages={errors.getErrorMessages("Name")}
              />
            </Field>
          </PureG>
          <PureG>
            <Field>
              <LabeledTextField
                required
                label="Username"
                value={data.username}
                onChange={this.onUsernameChanged}
                errorMessage={errors.getErrorMessage("Username")}
              />
            </Field>
          </PureG>
          <PureG>
            <Field>
              <LabeledTextField
                required
                label="Email"
                value={data.email}
                onChange={this.onEmailChanged}
                errorMessage={errors.getErrorMessage("Email")}
              />
            </Field>
          </PureG>
          <PureG>
            <Field>
              <LabeledPhoneField
                required
                isAreaCodeRequired
                label="Phone"
                phone={data.phone}
                onPhoneChange={this.onPhoneChanged}
                errorMessages={errors.getErrorMessages("Phone")}
              />
            </Field>
          </PureG>
          <PureG>
            <Field>
              <LabeledCheckboxField
                label="Please send me an informational non-profit e-newsletter from the Ohio Attorney General's Office"
                value={data.sendNewsletter}
                onToggle={this.onNewsletterToggled}
              />
            </Field>
          </PureG>
        </div>
        <ActionButtons>
          <ActionButton transparent onClick={props.closeDialog}>
            Cancel
          </ActionButton>
          <ActionButton primary onClick={this.onSaveAccountClicked}>
            Save Account
          </ActionButton>
        </ActionButtons>
        <div>
          You can also <Link onClick={this.onChangePasswordClicked}>change your password</Link>.
        </div>
      </Dialog>
    );
  }

  private updateData = (producer: (data: Data) => Data) => {
    return this.setState((state) => {
      return { data: UpdateEditorState(state.data, { next: producer(state.data.currentState) }) };
    });
  };

  private onNameChanged = (name: Name) =>
    this.updateData((data) => {
      return { ...data, name: name };
    });
  private onUsernameChanged = (value: string) =>
    this.updateData((data) => {
      return { ...data, username: value };
    });
  private onEmailChanged = (value: string) =>
    this.updateData((data) => {
      return { ...data, email: value };
    });
  private onPhoneChanged = (phone: PhoneNumber) =>
    this.updateData((data) => {
      return { ...data, phone: phone };
    });
  private onNewsletterToggled = () =>
    this.updateData((data) => {
      return { ...data, sendNewsletter: !data.sendNewsletter };
    });

  private onSaveAccountClicked = () => {
    const data = this.state.data.currentState;
    const request: EditUserAccountInfoRequest = {
      name: data.name,
      username: data.username,
      email: data.email,
      phone: data.phone,
      sendNewsletter: data.sendNewsletter,
    };
    this.props.accountsApiClient.editUserAccountInfo(request).then((response) => {
      if (!response.success) {
        this.setState((state) => {
          return { data: UpdateEditorState(state.data, { errors: response.errors }) };
        });
      } else {
        this.props.context.flashMessage("User Account updated");
        this.props.closeDialog();
      }
    });
  };

  private onChangePasswordClicked = () => {
    this.props.accountsApiClient.getChangePasswordInfo().then((response) => {
      this.props.context.openDialog((closeDialog) => {
        return (
          <ChangePasswordDialog
            closeDialog={closeDialog}
            passwordPolicyRules={response.passwordPolicyRules}
            accountsApiClient={this.props.accountsApiClient}
            flashMessageCallback={this.props.context.flashMessage}
          />
        );
      });
    });
  };
}
