import { FeedbackType } from "spry-react/SerialOperation/serialOperation";
import { ConcurrentPromises } from "spry-react/utils/concurrentPromises";
import { RpcCommandResponse } from "shared/Components/Api/CustomDTOs/RpcCommandResponse";

interface PostFetcher {
  postJson<TResponse>(url: string, data: any, concurrencyContext?: ConcurrentPromises, feedbackType?: FeedbackType): Promise<TResponse>;
}

interface ApiUrls {
  apiExecuteUrl: string;
  apiLoadUrl: string;
}

export class CommandFetcher {
  private readonly fetcher: PostFetcher;
  private readonly apiUrls: ApiUrls;

  constructor(fetcher: PostFetcher, apiUrls: ApiUrls) {
    this.fetcher = fetcher;
    this.apiUrls = apiUrls;
  }

  async commandExecute<TResponse>(command: string, data: any, concurrencyContext?: ConcurrentPromises): Promise<TResponse> {
    return await this.fetcher.postJson<TResponse>(
      this.buildUrl(this.apiUrls.apiExecuteUrl, command),
      {
        name: command,
        request: data,
      },
      concurrencyContext,
    );
  }

  async commandLoad<TResponse>(command: string, data?: any, concurrencyContext?: ConcurrentPromises): Promise<TResponse> {
    return await this.fetcher.postJson<TResponse>(
      this.buildUrl(this.apiUrls.apiLoadUrl, command),
      {
        name: command,
        request: data,
      },
      concurrencyContext,
      FeedbackType.LoadingBanner,
    );
  }

  private buildUrl = (baseurl: string, commandname: string) => {
    return `${baseurl}${commandname}`;
  };
}
